import React from "react"
import {Container,Row,Col} from "react-bootstrap";
import CustomImage from "../components/imagesComponents/CustomImage";
import Layout from "../components/layout"
import SEO from "../components/seo"
import stagingStyle from '../styles/staging.module.css'

const background = {backgroundColor:'#F8F9F9',paddingTop:'50px',paddingBottom:'50px'}

const StagingPage = () => (
  <Layout>
    <SEO title="Staging" />
    <body className={stagingStyle.font} >
    <Container style={background}>
      <Row>
        <Col sm={12} md={{span:10, offset:1}}>
          <div style={{textAlign:'justify'}}>
            <h3 className={stagingStyle.title} style={{color:'#E9BF3C'}}>Staging</h3> 
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={1}/>
        <Col sm={12} md={5}>
          <div style={{textAlign:'justify'}}>
          <p>We make a home look inviting—a place you want to live. The difference between a vacant home and a 
            well-furnished home is nothing short of show stopping. We often hear compliments and praise from agents, 
            prospective Buyers, as well as the professional real estate photographers.  The photographers view many 
            homes a day photographing them for a living, and they often comment that it was a pleasure to photograph 
            the home with our staging decor.</p>
        
            <p>Our furnishings are on trend with current styles and colors. We have years of experience in staging homes 
              of all sizes and shapes.</p>
  
          </div>
        </Col>
        <Col sm={12} md={5} style={{paddingTop:'5px'}}>
          {/* <CustomImage src={'staging.jpg'} styles={{minWidth:'300px'}}/> */}
          <div style={{ 
                backgroundImage:`url("../../images/staging.jpg")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition:'center',
                backgroundSize:"cover",
                width:'100%'}} className={stagingStyle.img}/>
        </Col>
        <Col sm={12} md={1}/>
      </Row>
      <Row>

        <Col sm={12} md={{span:10, offset:1}}>

          <div style={{textAlign:'justify'}}>
            <p>If you are living in the home prior to selling we can utilize your furnishings to 
              achieve a stylish presentation. It is exciting to see the transformation over and over again.</p>
      
            <p>The special skills of staging are also applicable to planning furniture placement for a homeowner who 
              chooses to age-in-place. We tend to get set in our ways as time foes by. Planning spaces by better 
              organization, arranging furnishings, minimizing for ergonomics, and adding some of the latest gadgets or 
              décor for a new look that functions well is a service we offer. No one is as impressed by the results as 
              those who have lived in the home for years not knowing the potential of their home.</p>
          </div>
        </Col>

      </Row>
      <Row className={"justify-content-md-center"} >
        <Col sm={12} md={5}>
        <div style={{ 
              backgroundImage:`url("../../images/stagingBefore.jpg")`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition:'center',
              backgroundSize:"cover",
              width:'100%',
              height:'300px',
              padding: '5%'
              }} className={stagingStyle.imagebefore}>

                <div className={stagingStyle.caption}>Before</div>
              </div>
        </Col>
        <Col sm={12} md={5}>
        <div style={{ 
              backgroundImage:`url("../../images/stagingAfter.jpg")`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition:'center top',
              backgroundSize:"cover",
              width:'100%',
              height:'300px',
              padding: '5%'
              }} className={stagingStyle.imageafter}>
                <div className={stagingStyle.caption}>After</div>
              </div>
        </Col>
      </Row>
    </Container>
    </body>
  </Layout>
)

export default StagingPage